(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@igps/client-root-config"), require("@igps/dashboard-grid"), require("@igps/top-utility"));
	else if(typeof define === 'function' && define.amd)
		define(["@igps/client-root-config", "@igps/dashboard-grid", "@igps/top-utility"], factory);
	else if(typeof exports === 'object')
		exports["IGPS-Client-Dashboard-Cards-UI"] = factory(require("@igps/client-root-config"), require("@igps/dashboard-grid"), require("@igps/top-utility"));
	else
		root["IGPS-Client-Dashboard-Cards-UI"] = factory(root["@igps/client-root-config"], root["@igps/dashboard-grid"], root["@igps/top-utility"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__946__, __WEBPACK_EXTERNAL_MODULE__834__, __WEBPACK_EXTERNAL_MODULE__9__) => {
return 