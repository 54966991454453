import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { cardsId, ICardModel } from '../models/card.model';
import { assumedRole, ProcessDataModel } from '../models/processDataModel';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  readonly GTbaseUrl = `${environment.genericTaskBase.apiUrl}`;
  readonly DomainDatabaseUrl = `${environment.DomainDataBase.apiUrl}`;
  readonly baseUrl = `${environment.igpsBase.apiUrl}`;

  constructor(private httpClient: HttpClient) { }

  private getCardOrder(id: number): number {
    let order = 0;
    switch (id) {
      case cardsId.MyTask: // My Tasks
        order = 1;
        break;
      case cardsId.Onshore: // Onshore
        order = 2;
        break;
      case cardsId.Query: // Query
        order = 3;
        break;
      case cardsId.New: // New
        order = 4;
        break;
      case cardsId.InProgress: // In Progress
        order = 5;
        break;
      case cardsId.OnHold: // On Hold
        order = 6;
        break;
      case cardsId.Bot: // Bot
        order = 7;
        break;
      case cardsId.Pending: // Pending
        order = 8;
        break;
      case cardsId.LostAndFound: // Lost and found
        order = 11;
        break;
    }
    return order;
  }

  getCardsData(ProductionProcessId: string, assumedRoleId: any, sourcetype: any) {
    const role = assumedRoleId === assumedRole.processor ? "process" : "manager";
    const order = assumedRoleId === assumedRole.processor ? this.getCardforassumedrole : this.getCardOrder;
    if (sourcetype == 2) {
      return this.httpClient.get<ICardModel[]>(`${this.baseUrl}/emailservice/dashboard/v1/${role}/cardcount/${ProductionProcessId}`).pipe(
        map(cards => {
          let orderedCards = cards.map(c => { return { ...c, order: order(c.id) } });
          orderedCards = orderedCards.sort((a, b) => a.order - b.order);
          return orderedCards;
        })
      );
    } else {
      return this.httpClient.get<ICardModel[]>(`${this.GTbaseUrl}/gentask/v1/task/${role}/${ProductionProcessId}`).pipe(
        map(cards => {
          let orderedCards = cards.map(c => { return { ...c, order: order(c.id) } });
          orderedCards = orderedCards.sort((a, b) => a.order - b.order);
          return orderedCards;
        })
      );
    }
  }
  getCardforassumedrole(id: number): any {
    let order = 0;
    switch (id) {
      case cardsId.MyTask: // My Tasks
        order = 1;
        break;
      case cardsId.New: // New
        order = 2;
        break;
      case cardsId.Complete://complete
        order = 3;
        break;
    }
    return order;
  }

  getProcessDetail(processId: string): Observable<ProcessDataModel> {
    return this.httpClient.get<ProcessDataModel>(`${this.DomainDatabaseUrl}/prdprocess/v1/id/${processId}`);
  }



}
