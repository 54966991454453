export class Events{
  public static TriggerEvents: string[] = [
    // "tasknotesaddedevent",
    // "tasknotesupdatedevent",
    // "taskduedatechangedevent",
    "taskstartedevent",
    "orderrequestedevent",
    "ordercancelledevent",
    "taskreturnedtoigpsevent",
    "taskcancelledevent",
    "taskassignedevent",
    "taskunassignedevent",
    "taskreturnedtobranchevent",
    "tasksenttoqueryevent",
    "taskputonholdevent",
    "taskremoveholdevent",
    "taskescalatedtoonshoreevent",
    "taskcompletedevent",
    "taskcompletedwithexceptionsevent",
    "tasksenttoprocessorevent",
    "taskcancelledbyipsevent",
    "createnewtaskevent",
    "emailsenttoqueryevent",
    "emailputonholdevent",
    "emailremoveholdevent",
    "emailescalatedtoonshoreevent",
    "emailsenttoprocessorevent",
    "emaillostandfoundevent",
    "emailmarkasspam",
    "emailremovespam",
    "emailassignedevent",
    "emailunassignedevent",
    "emailprioritychangedevent",
    // "emailnotesaddedevent",
    // "emailnotesupdatedevent",
    // "emailnotesstatuschangedevent",
    "emaillinkedtotaskevent",
    "emailmovedevent",
    "emailstartedevent",
    "emailreceivedevent",
  ];
}
