export interface ICardModel {
  id: number;
  cardName: string;
  count: number;
}

export enum cardsId {
  MyTask = 0,
  New = 1,
  Complete = 777,
  Onshore = 4,
  Query = 3,
  InProgress = 2,
  OnHold = 5,
  Bot = 8,
  Pending = 10,
  LostAndFound = 11
}