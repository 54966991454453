<igps-lib-spinner [showSpinner]="showSpinner"></igps-lib-spinner>
<lib-toast></lib-toast>
<div class="main-container">
  <div class="header">
    <span>{{selectedProductionProcessName}} Dashboard</span>
  </div>
  <div class="cardcontainer">
    <div class="lostandfound-button-div" *ngIf="cards.length > 0 && lostandfoundCard && !isdisbaled">
      <div class="button" [ngClass]="{'active': (DashboardSelectedCard)?.id == lostandfoundCard.id }"
        (click)="onCardClick(lostandfoundCard)">
        <span>{{lostandfoundCard.cardName}}</span>
        <span>{{lostandfoundCard.count}}</span>
      </div>
    </div>
    <div class="completed-button-div" *ngIf="cards.length > 0 && completedCard && !isdisbaled">
      <div class="button" [ngClass]="{'active': (DashboardSelectedCard)?.id == completedCard.id }"
        (click)="onCardClick(completedCard)">
        <span>{{completedCard.cardName}}</span>
        <span>{{completedCard.count}}</span>
      </div>
    </div>
  </div>



  <div class="cards-div" *ngIf="cards.length > 0">
    <ng-container *ngFor="let card of cards">
      <div class="card" *ngIf="cardDisplay(card)">
        <app-card [card]="card" [carddisabled]="isdisbaled" [selectedcard]="DashboardSelectedCard"
          (cardClick)="onCardClick($event)"></app-card>
      </div>
    </ng-container>
  </div>

</div>