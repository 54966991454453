import { ICardModel, cardsId } from './../../models/card.model';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { cardSelectEvent, reloadEvent, resetTimerEvent, searchInputEvent, selectedProcessEvent, cardsEvent } from '@igps/dashboard-grid';
import { finalize, forkJoin, Observable, Subscription } from 'rxjs';
import { DashboardService } from '../../services/dashboard.service';
import { GetSelectedProcess, onChangeProcess } from '@igps/top-utility';
import { ToastComponent } from '@ui-core/toast';
import { HttpErrorResponse } from '@angular/common/http';
import { getUserInfo, ipsWsMessages$ } from '@igps/client-root-config';
import { Events } from '../../models/Events';
import { cardsCountEvent } from '../../../main.single-spa';
import { assumedRole } from 'src/app/models/processDataModel';

let cardsCountData!: ICardModel[];
export const getCardsCount: () => ICardModel[] = () => cardsCountData;

@Component({
  selector: 'app-process-dashboard',
  templateUrl: './process-dashboard.component.html',
  styleUrls: ['./process-dashboard.component.scss']
})
export class ProcessDashboardComponent implements OnInit, OnDestroy {
  isdisbaled: boolean = false;
  selectedProcessSourceType: any;
  lostandfoundCard!: ICardModel;

  constructor(private dashboardService: DashboardService, private cdref: ChangeDetectorRef) { }

  // onfocus = () => {
  //   this.removeReloadAndResetSubscriptions();
  //   this.addReloadIntervalSubscription();
  //   // this.addResetTimerSubscription();
  // };
  // onblur = () => {
  //   this.removeReloadAndResetSubscriptions();
  // };

  @ViewChild(ToastComponent) toaster!: ToastComponent;
  showSpinner: boolean = false;

  searchInputChangeSubscription!: Subscription;
  onChangeProcessIdSubscription!: Subscription;
  reloadSubscription!: Subscription;
  reloadIntervalSubscription!: Subscription;
  resetTimerSubscription!: Subscription;
  WebSockectSubscription!: Subscription;

  // refreshIntervalInSeconds: number = 120;

  ngOnDestroy(): void {
    this.searchInputChangeSubscription.unsubscribe();
    this.onChangeProcessIdSubscription.unsubscribe();
    this.reloadSubscription.unsubscribe();
    this.WebSockectSubscription.unsubscribe();
    // this.reloadIntervalSubscription.unsubscribe();
    // this.resetTimerSubscription.unsubscribe();
    // removeEventListener('focus', this.onfocus);
    // removeEventListener('blur', this.onblur);
  }

  DashboardSelectedCardSubjectObservable!: Observable<ICardModel>;
  DashboardSelectedCard!: ICardModel | null;

  selectedProductionProcessId!: string;
  selectedProductionProcessName!: string;

  completedCard!: ICardModel;

  cards: ICardModel[] = [];
  searchText: string | null = null;

  ngOnInit(): void {
    // addEventListener('focus', this.onfocus);
    // addEventListener('blur', this.onblur);
    this.searchInputChangeSubscription = searchInputEvent.subscribe(d => {
      if (d.trim().length > 0) {
        this.DashboardSelectedCard = null;
        this.searchText = d.trim();
      }
      else {
        this.searchText = null;
        var mytask = this.cards.find(c => c.id == cardsId.MyTask) as ICardModel;
        this.onCardClick(mytask);
      }
      this.cdref.detectChanges();
    });
    this.reloadSubscription = reloadEvent.subscribe(d => {
      this.getCardsData(false);
    });
    this.onChangeProcessIdSubscription = onChangeProcess.subscribe(d => {
      this.selectedProductionProcessId = d.id;
      this.selectedProductionProcessName = d.name;
      this.selectedProcessSourceType = d.taskSource;
      if (this.selectedProductionProcessId) {
        this.getCardsData(false);
      }
    });
    if (!this.selectedProductionProcessId) {
      const process = GetSelectedProcess();
      if (process?.id) {
        this.selectedProductionProcessId = process.id;
        this.selectedProductionProcessName = process.name;
        this.selectedProcessSourceType = process.taskSource;
        this.getCardsData(false);
      }
    }
    // this.addReloadIntervalSubscription();
    // this.addResetTimerSubscription();
    this.WebSockectSubscription = ipsWsMessages$.subscribe(data => {
      if (Events.TriggerEvents.includes(data?.EventName?.toLowerCase())) {
        this.getCardsData(true);
      }
    })
  }

  getCardsData(isAutoRefresh: boolean) {
    resetTimerEvent.emit();
    let userinfo = getUserInfo();
    if (userinfo && userinfo.assumedRoleId === 2) {
      this.isdisbaled = true;
    } else {
      this.isdisbaled = false;
    }
    if (!isAutoRefresh) {
      this.showSpinner = true;
    }
    this.cdref.detectChanges();
    forkJoin({
      processData: this.dashboardService.getProcessDetail(this.selectedProductionProcessId),
      cardsData: this.dashboardService.getCardsData(this.selectedProductionProcessId, userinfo.assumedRoleId, this.selectedProcessSourceType)
    }).pipe(
      finalize(() => {
        this.showSpinner = false;
        this.cdref.detectChanges();
      })
    ).subscribe({
      next: (data) => {
        let processData = data.processData;
        let cardsData = data.cardsData;
        cardsCountData = data.cardsData;
        cardsCountEvent.emit(cardsData);
        cardsEvent.emit(cardsData)
        cardsData = cardsData.filter(c => c.id !== cardsId.Pending);
        if (!(processData.hasBotQueue)) {
          cardsData = cardsData.filter(c => c.id !== cardsId.Bot);
        }
        if (!(processData.hasOnshoreQueue && userinfo.site.name.toLowerCase() === "onshore")) {
          cardsData = cardsData.filter(c => c.id !== cardsId.Onshore);
        }
        let mytask = cardsData.find(c => c.id == cardsId.MyTask) as ICardModel;
        this.completedCard = cardsData.find(c => c.id == cardsId.Complete) as ICardModel;

        this.lostandfoundCard = cardsData.find(c => c.id == cardsId.LostAndFound) as ICardModel;

        if (this.isdisbaled) {
          this.cards = cardsData;
        } else {
          this.cards = cardsData.filter(c => c.id != cardsId.Complete);
          this.cards = this.cards.filter(c => c.id != cardsId.LostAndFound);
        }
        if ((this.DashboardSelectedCard == null && this.searchText == null) || (this.searchText == null && this.DashboardSelectedCard != null)) {
          if (this.DashboardSelectedCard && cardsData.some(c => c.id == this.DashboardSelectedCard!.id)) {
            this.DashboardSelectedCard = cardsData.find(c => c.id == this.DashboardSelectedCard!.id)!;
          }
          else {
            this.DashboardSelectedCard = mytask;
          }
        }
        cardSelectEvent.emit({ card: this.DashboardSelectedCard, processId: this.selectedProductionProcessId, isAutoRefresh });
        selectedProcessEvent.emit(processData);
        this.cdref.detectChanges();
      },
      error: (error: HttpErrorResponse) => {
        if (error.error.Type === "HttpRequestException") {
          this.openToast(error.error.Title, true);
        }
        else {
          this.openToast(error.error.Detail, true);
        }
      }
    });
  }

  cardDisplay(card: ICardModel): boolean {
    let userinfo = getUserInfo();
    if (userinfo.assumedRoleId === assumedRole.processor) {
      return ([cardsId.MyTask, cardsId.New, cardsId.Complete]).includes(card.id) ? true : false; // display only mytasks, new and completed cards for processor
    }
    else {
      return true;
    }
  }

  onCardClick(card: ICardModel) {
    this.DashboardSelectedCard = card;
    cardSelectEvent.emit({ card: this.DashboardSelectedCard, processId: this.selectedProductionProcessId, isAutoRefresh: false });
    this.cdref.detectChanges();
  }

  // private addReloadIntervalSubscription(){
  //   this.reloadIntervalSubscription = interval(this.refreshIntervalInSeconds * 1000).subscribe(() => {
  //     this.getCardsData(true);
  //   });
  // }

  // private addResetTimerSubscription(){
  //   this.resetTimerSubscription = resetTimerEvent.asObservable().pipe(debounceTime(1000)).subscribe(() => {
  //     if (this.reloadIntervalSubscription) {
  //       this.reloadIntervalSubscription.unsubscribe();
  //       this.reloadIntervalSubscription = interval(this.refreshIntervalInSeconds * 1000).subscribe(() => {
  //         this.getCardsData(true);
  //       });
  //     }
  //   });
  // }

  // private removeReloadAndResetSubscriptions() {
  //   if (this.reloadIntervalSubscription) {
  //     this.reloadIntervalSubscription.unsubscribe();
  //   }
  //   // if (this.resetTimerSubscription) {
  //   //   this.resetTimerSubscription.unsubscribe();
  //   // }
  // }

  openToast(message: string, isError: boolean = false) {
    this.toaster.toastMessage = message;
    this.toaster.actionRightText = 'close';
    this.toaster.showToast = true;
    this.toaster.error = isError;
    this.toaster.toasterTimeout();
    setTimeout(() => {
      this.cdref.detectChanges();
    }, 5100);
  }

}
