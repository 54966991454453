import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpinnerModule } from '@igps/spinner';
import { IconModule } from '@ui-core/icon';
import { ToastModule } from '@ui-core/toast';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardComponent } from './components/card/card.component';
import { ProcessDashboardComponent } from './components/process-dashboard/process-dashboard.component';
import { HttpInterceptor } from './interceptor/header-interceptor';

@NgModule({
  declarations: [
    AppComponent,
    CardComponent,
    ProcessDashboardComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IconModule,
    SpinnerModule,
    ToastModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: HttpInterceptor
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
