export interface ProcessDataModel {
  taskSource: number;
  id: string;
  name: string;
  hasBotQueue: boolean;
  hasOnshoreQueue: boolean;
  stopBot: boolean;
  linkToManual: string;
  statusId: number;
  productionSystemId: string;
  productionSystemName: string;
  productionServiceId: string;
  productionServiceName: string;
  productionServiceCategoryId: string;
  productionServiceCategoryName: string;
  createdDateUTC: string;
  createdBy: CommonDataModel;
  lastUpdatedBy: CommonDataModel;
  lastUpdatedDateUtc: string;
  taskType: number;
  slaInHours: number;
  dashboardUrl: string;
  clientTableName: string;
  exposeToIgnite: boolean;
}

export interface ProcessLiteDataModel {
  id: string;
  name: string;
  linkToManual: string;
}

export interface CommonDataModel {
  id: string;
  name: string;
}
export enum assumedRole {
  processor = 2,
  manager = 1,
}