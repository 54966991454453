import { Component } from '@angular/core';
import { faBot, faCheckmark, faClock, faGlobe, FaIconsRegistry, faInProgressCircle, faListBullet, faPause, faRotate } from '@ui-core/icon';

@Component({
  selector: 'igps-dashboard-cards',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'IGPS-Client-Dashboard-Cards-UI';

  constructor(private faIconsRegistry: FaIconsRegistry,) {
    this.faIconsRegistry.registerIcons([faListBullet, faGlobe, faRotate, faClock, faInProgressCircle, faPause, faBot, faCheckmark]);
  }


}
