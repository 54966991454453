<div [class]="'card-'+card.id"
  [ngClass]="{'active': (selectedcard)?.id == card.id ,'disabledNewCard':card.id==1 && carddisabled}"
  (click)="onCardClick()">
  <div class="top">
    <div class="icon">
      <lib-icon name="list_bullet" *ngIf="card.id == 0"></lib-icon>
      <lib-icon name="globe" *ngIf="card.id == 4"></lib-icon>
      <lib-icon name="rotate" *ngIf="card.id == 3"></lib-icon>
      <lib-icon name="clock" *ngIf="card.id == 1"></lib-icon>
      <lib-icon name="in_progress_circle" *ngIf="card.id == 2"></lib-icon>
      <lib-icon name="pause" *ngIf="card.id == 5"></lib-icon>
      <lib-icon name="bot" *ngIf="card.id == 8"></lib-icon>
      <lib-icon name="checkmark" *ngIf="card.id == 777"></lib-icon>
    </div>
    <div class="count">
      <span> {{card.count}} </span>
    </div>
  </div>
  <div class="bottom">
    <span> {{card.cardName}} </span>
  </div>
</div>