import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ICardModel } from '../../models/card.model';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {

  @Input() card!: ICardModel;
  @Input() selectedcard!: ICardModel | null;
  @Output() cardClick: EventEmitter<ICardModel> = new EventEmitter<ICardModel>();
  @Input() carddisabled: boolean = false
  cardtype: string | null = null;

  DashboardSelectedCardSubjectObservable!: Observable<ICardModel | null>;
  DashboardSelectedCardSubjectObservableSubscription!: Subscription;

  onCardClick() {
    this.cardClick.emit(this.card);
  }

}
